import React from 'react'
import { graphql, Link as GatsbyLink } from 'gatsby'
import Link from '../utilities/link'
import Img from 'gatsby-image'
import i18n from '../i18n'
import Seo from '../components/seo/seo'
import SiteNavigation from '../components/header/siteNavigation'
import CommonFooter from '../components/footer/commonFooter'
import { useTranslation } from 'react-i18next'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'


const ProductFeatureTemplate = ({ data }) => {

    const {
      title,
      photo,
      pathtoProduct,
      product,
    } = data.markdownRemark.frontmatter

    const { edges } = data.allMarkdownRemark
    const { t } = useTranslation();
    const productImage = data.markdownRemark.frontmatter.photo

    return (
      <>
        <Seo
          title={title}
          description={title}
          seoImage={photo.childImageSharp.resize.src}
        />
        <SiteNavigation />

        <div className="section">
          <div className="md:flex py-8 md:space-x-8">
            <div className="md:w-1/3">
              <div>
                <div
                  style={{
                    position: 'relative',
                    zIndex: 1,
                    overflow: 'hidden',
                    bottom: 0,
                    top: 0,
                    height: '100%',
                    width: '100%',
                    opacity: 1,
                  }}
                >
                  <Img fluid={photo.childImageSharp.fluid} />
                </div>
              </div>
              <ul className="menu-list">
                <li>
                  <GatsbyLink
                    to={pathtoProduct}
                    className="is-active"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {product}
                  </GatsbyLink>
                  <ul>
                    {edges
                      .filter(({ node }) => {
                        return (
                          node.frontmatter.lang ===
                          i18n.language.split('-')[0]
                        )
                      })
                      .map(({ node }) => {
                        const { title, translatedPath } = node.frontmatter
                        return (
                          <li>
                            <Link to={`/${translatedPath}`}>{title}</Link>
                          </li>
                        )
                      })}
                  </ul>
                </li>
              </ul>
            </div>
            <div className="md:w-2/3 bg-gray-100 p-8">
              <div className="content">
                <h1 className="title is-1 font-has-gradient">{title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.markdownRemark.html,
                  }}
                />
              </div>
              <div className="columns">
                <div className="column is-6 has-text-centered">
                  <GatsbyLink to={pathtoProduct}>
                    <p
                      className="font-semibold text-center text-xl p-8 border-2 border-green-500 rounded-xl"
                      style={{
                        color: '#35b775',
                        textTransform: 'capitalize',
                      }}
                    >
                      {product}
                    </p>
                  </GatsbyLink>
                </div>
                <div className="column is-6 has-text-centered">
                  <a href="mailto:info@ctaelectronics.com">
                    <p className="font-semibold text-center text-xl p-8 border-2 border-green-500 rounded-xl">
                      {t('footer.Button')}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CommonFooter />
      </>
    )
}

export default ProductFeatureTemplate

export const ProductFeatureQuery = graphql`
  query ProductFeature($localizedPath: String!, $product: String!) {
    markdownRemark(fields: { slug: { eq: $localizedPath } }) {
      html
      frontmatter {
        lang
        path
        translatedPath
        product
        pathtoProduct
        title
        photo {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 900, quality: 90) {
              src
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { product: { in: [$product] } } }
    ) {
      edges {
        node {
          frontmatter {
            lang
            path
            translatedPath
            product
            title
          }
        }
      }
    }
  }
`
